.bp-video-widget {
  width: 100%;
}

.bp-image-widget {
  max-width: 100%;
}

.signboard {
  padding: 0 9.1666666667vw 10.07vw 9.1666666667vw;
  background: #ebeced;
  &__title {
    padding-top: 8.33vw;
    font-size: 3.19vw;
    display: flex;
    justify-content: center;
    color: #3A3E41;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__list {
    padding-top: 5.69vw;
    .apos-area {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      section:not(:nth-child(3n)) {
        .signboard-block {
          border-right: 1px solid #E2E5E8;
        }
      }
      section:last-child {
        .signboard-block {
          border-right: none;
        }
      }
    }
  }
  &-block {
    width: 26.55vw;
    background: #FFFFFF;
    &__logos {
      height: 3.4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.94vw 2.5vw;
      &--company {
        img {
          width: 5.49vw;
          height: 3.4vw;
        }
      }
      &--logo {
        img {
          height: 2.5vw;
          width: 2.5vw;
        }
      }
    }
    &__image {
      padding: 4.31vw 1.94vw 3.26vw 1.94vw;
      img {
        max-height: 9.98vw;
      }
    }
    &__link {
      width: 26.55vw;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5.28vw;
      border-top: 1px solid #E2E5E8;
      border-bottom: 1px solid #E2E5E8;
      a {
        color: #3A3E41;
        font-size: 0.97vw;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        text-decoration: none;
      }
      img {
        width: 0.83vw;
        height: 0.83vw;
        margin-left: 0.69vw;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .signboard {
    padding: 0 8.53vw 10.07vw 8.53vw;
    background: #ebeced;
    &__title {
      padding-top: 17.07vw;
      font-size: 9.60vw;
      display: flex;
      justify-content: center;
      color: #3A3E41;
      font-weight: 500;
      text-transform: uppercase;
    }
    &__list {
      padding-top: 12.8vw;
      .apos-area {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &-block {
      width: 82.93vw;
      background: #FFFFFF;
      margin-bottom: 8.53vw;
      &__logos {
        height: 9.6vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6.4vw;
        &--company {
          img {
            width: 17.6vw;
            height: 9.6vw;
          }
        }
        &--logo {
          img {
            width: 9.6vw;
            height: 9.6vw;
          }
        }
      }
      &__image {
        padding: 9.6vw 6.93vw 11.73vw 4vw;
        img {
          max-height: 24.80vw;
        }
      }
      &__link {
        width: 82.93vw;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18.13vw;
        border-top: 1px solid #E2E5E8;
        border-bottom: 1px solid #E2E5E8;
        a {
          color: #3A3E41;
          font-size: 3.73vw;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          text-decoration: none;
        }
        img {
          width: 3.2vw;
          height: 3.2vw;
          margin-left: 4.27vw;
        }
      }
    }
  }
}
