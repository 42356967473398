::selection {
  background-color: #08ada740 !important;
}

.pct-header {
  font-size: 1em;
  z-index: 1;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0 9.166666666666666vw;
  &-mobile {
    display: none;
  }
  &__row-main {
    height: 6.25vw;
    display: flex;
    border-bottom: 1px solid #E3E4E5;
  }
  &__logo {
    height: 3.33vw;
    width: 6.25vw;
    padding: 1.39vw 2.78vw 1.39vw 0;
  }
  &__line {
    border-left: 1px solid #E3E4E5;
  }
  &__search {
    height: 3.33vw;
    width: 47.22vw;
    padding: 1.39vw 7.03vw 1.39vw 2.78vw;
    display: flex;
    &--form {
      display: flex;
      select:not([multiple]) {
        padding-right: 1.2em;
        background-repeat: no-repeat;
        background-size: 0.85em auto;
        background-position: calc(100% - 0.25em) 1.5em;
        background-image: url(/images/icon-arrow.svg);
      }
      select::-ms-expand {
        display: none;
      }
      input::-webkit-input-placeholder {
        opacity: 0.5
      }
    }
    &--select {
      height: 100%;
      border: 1px solid #E3E4E5;
      background: #F7F7F7;
      outline: none;
      cursor: pointer;
      font-size: 0.97vw;
      font-family: "Lexend", sans-serif;
      appearance: none;
      color: #3A3E41;
      text-align-last: center;
    }
    &--option {
      outline: none;
      font-size: 0.97vw;
      font-family: "Lexend", sans-serif;
    }
    &--input {
      height: 100%;
      outline: none;
      padding: 0;
      padding-left: 1.74vw;
      border: none;
      border-top: 1px solid #E3E4E5;
      border-bottom: 1px solid #E3E4E5;
      width: 36.11vw;
      font-family: "Lexend", sans-serif;
      font-size: 1.04vw;
      color: #939699;
    }
    &--btn {
      height: 100%;
      width: 4.72vw;
      border: none;
      border-top: 1px solid #08ADA7;
      border-bottom: 1px solid #08ADA7;
      outline: none;
      background: #08ADA7;
      padding: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--icon {
      width: 1.11vw;
      height: 1.11vw;
    }
  }
  &__phone {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.29vw;
    font-weight: bold;
    width: 14.26vw;
    color: #3A3E41;
    text-decoration: none;
    &--icon {
      width: 1.18vw;
      height: 1.18vw;
    }
    &--number {
      text-decoration: none !important;
      width: 10.90vw;
      color: #3A3E41;
      cursor: pointer;
    }
  }
  &__row-nav {
    display: flex;
    justify-content: space-between;
    height: 4.17vw;
    align-items: center;
    &--menu {

    }
    &--actions {
      display: flex;
      .erp-nav__dropdown-item {
        a {
          color: rgba(48, 59, 74, 0.85);
          text-decoration: none;
          &:hover {
            color: #08ADA7 !important;
          }
        }
      }
      .erp-nav__logout {
        a {
          text-decoration: none;
          color: rgba(48, 59, 74, 0.85);
        }
      }
    }
  }
  &__signin {
    display: flex;
    align-items: center;
    &--icon {
      width: 1.32vw;
      height: 1.32vw;
      margin-right: 0.49vw;
    }
    &--link {
      font-size: 0.97vw;
      font-weight: 600;
      color: #3A3E41;
      text-decoration: none !important;
      &:hover {
        color: #08ADA7;
      }
    }
  }
  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    //justify-content: space-between;
  }
  &__list {
    display: flex;
    align-items: center;
    padding: 0;
    text-transform: uppercase;
  }
  &__link {
    list-style-type: none;
    font-style: normal;
    font-weight: 500;
    font-size: 1.111vw;
    line-height: 1.389vw;
    margin-right: 1.182vw;
    &--active {
      color: #08ADA7 !important;
    }
    a {
      text-decoration: none;
      color: #A0A9B0;
      font-size: 0.97vw;
      &:hover {
        color: #08ADA7;
      }
    }
  }
  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    border: 0;
    font-size: 1em;
    z-index: 1;
  }
}

.pct-content {
  &-container {
    padding: 0 9.1666666667vw;
  }
  &-board {
    padding: 0 9.1666666667vw;
    &-mobile {
      display: none;
    }
    &__info {
      display: flex;
      height: 34.03vw;
    }
    &__col {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    &__text {
      font-size: 3.19vw;
      line-height: 4.17vw;
      color: #3A3E41;
      font-weight: 500;
      width: 33.68vw;
    }
    &__btn {
      margin-top: 2.22vw;
    }
    &__link {
      width: 10.9vw;
      height: 3.68vw;
      background: #08ADA7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF !important;
      text-decoration: none !important;
      font-size: 0.97vw;
      border-radius: 0.14vw;
    }
    &__img {
      width: 41.74vw;
      height: 23.82vw;
    }
    &__slogan {
      display: flex;
      height: 2.99vw;
      border-top: 1px solid #E4E8EB;
      padding: 2.43vw 0;
      &--block {
        display: flex;
        flex: 1;
        align-items: center;
        font-size: 0.97vw;
        text-transform: uppercase;
        color: #3A3E41;
      }
    }
  }
}

.pct {
  &-footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 37.71vw;
    background: #3A3E41;
    display: flex;
    flex-direction: column;
    &-mobile {
      display: none;
    }
    &__menu {
      padding: 0 9.1666666667vw;
      display: flex;
      height: 30.28vw;
    }
    &__logo {
      padding-top: 5.56vw;
      margin-right: 12.01vw;
      img {
        width: 8.68vw;
        height: 4.65vw;
      }
    }
    &__nav {
      padding-top: 6.11vw;
      display: flex;
      height: 22.5vw;
    }
    &__block {
      &--title {
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 1.11vw;
        line-height: 1.67vw;
        opacity: 70%;
        margin-bottom: 0.83vw;
      }
      &--link {
        padding: 0.83vw 0;
        a {
          color: #A0A9B0;
          font-size: 1.11vw;
          text-decoration: none;
          &:hover {
            color: #08ADA7;
          }
        }

      }
      &--social {
        display: flex;
        &-link {
          text-decoration: none;
          cursor: pointer;
          height: 2.78vw;
          width: 2.78vw;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #2B2E30;
          border-radius: 5px;
          img {
            height: 1.11vw;
            width: 1.11vw;
          }
        }
      }
    }
    &__line {
      height: 1px;
      background: #A0A9B0;
      margin: 0 9.1666666667vw;
      opacity: 20%;
    }
    &__info {
      padding: 0 9.1666666667vw;
      height: 7.15vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--group {
        display: flex;
        font-size: 0.83vw;
        color: #A0A9B0;
        opacity: 70%;
      }
    }

    &__col {
      width: 12vw;
      display: flex;
      flex-direction: column;
      &:first-child {
        width: 25.972vw;
      }
      &:nth-child(2) {
        width: 25.569vw;
      }
      &:last-child {
        width: 14vw;
      }
      li {
        //margin-bottom: 12px;
        margin-bottom: 0.833vw;
      }
      &-title {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 1.189vw;
        line-height: 1.667vw;
        color: #FFFFFF;
        margin-bottom: 2.222vw;
      }
      &-link {
        margin-bottom: 0.833vw;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.389vw;
        color: rgba(255, 255, 255, 0.85);
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.85);
        }
        a:hover {
          color: #3776CF;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pct-header {
    display: none;
  }
  .pct-content-container {
    padding: 0 8.53vw;
  }
  .pct-content-board {
    display: none;
  }
  .pct-footer {
    display: none;
  }
  .pct-header-mobile {
    display: flex;
    flex-direction: column;
    height: 35.20vw;
    padding: 0 8.53vw;
    &__main {
      height: 22.40vw;
      display: flex;
    }
    &__menu {
      padding-top: 12.6vw;
      margin-right: 19.73vw;
      font-size: 2.93vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      img {
        width: 7.47vw;
        height: 4.27vw;
      }
    }
    &__logo {
      padding-top: 4.53vw;
      margin-right: 15.8vw;
      img {
        width: 28vw;
        height: 14.93vw;
      }
    }
    &__signin {
      padding-top: 9.6vw;
      font-size: 2.93vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #3A3E41;
      &--block {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &--icon {
        height: 7.47vw;
        width: 7.47vw;
      }
    }
    &__search {
      height: 11.2vw;
      width: 82.93vw;
      display: flex;
      &--form {
        display: flex;
        select:not([multiple]) {
          padding-right: 1.2em;
          background-repeat: no-repeat;
          background-size: 0.85em auto;
          background-position: calc(100% - 0.25em) 1.34em;
          background-image: url(/images/icon-arrow.svg);
        }
        select::-ms-expand {
          display: none;
        }
        input::-webkit-input-placeholder {
          opacity: 0.5
        }
      }
      &--select {
        height: 100%;
        border: 1px solid #E3E4E5;
        background: #F7F7F7;
        outline: none;
        cursor: pointer;
        font-size: 3.73vw;
        font-family: "Lexend", sans-serif;
        appearance: none;
        color: #3A3E41;
        text-align-last: center;
      }
      &--option {
        outline: none;
        font-size: 3.73vw;
        font-family: "Lexend", sans-serif;
      }
      &--input {
        height: 100%;
        outline: none;
        padding: 0;
        padding-left: 2.20vw;
        border: none;
        border-top: 1px solid #E3E4E5;
        border-bottom: 1px solid #E3E4E5;
        width: 48.40vw;
        font-family: "Lexend", sans-serif;
        font-size: 3.73vw;
        color: #939699;
      }
      &--btn {
        height: 100%;
        width: 13.33vw;
        border: none;
        border-top: 1px solid #08ADA7;
        border-bottom: 1px solid #08ADA7;
        outline: none;
        background: #08ADA7;
        padding: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--icon {
        width: 4.27vw;
        height: 4.27vw;
      }
    }
    &__modal {
      position: absolute;
      top: 22.2vw;
      left: 0;
      height: 90%;
      width: 100%;
      background: #FFFFFF;
      display: none;
      z-index: 3;
      &--container {
        padding: 10.93vw 8.53vw;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &--menu {

      }
      &--list {
        width: 100%;
        padding: 0;
        text-transform: uppercase;
      }
      &--link {
        list-style-type: none;
        font-size: 4.80vw;
        margin-bottom: 8vw;
        &-active {
          color: #08ADA7 !important;
        }
        a {
          text-decoration: none;
          color: #A0A9B0;
        }
      }
      &--footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-block {
          width: 12.8vw;
          height: 12.8vw;
          border: 1px solid #E3E4E5;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.73vw;
        }
        &-number {
          font-size: 5.33vw;
          color: #3A3E41;
          text-decoration: none;
          font-weight: 600;
        }
        img {
          width: 4.27vw;
          height: 4.27vw;
        }
      }
    }
  }

  .pct-content-board-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 8.53vw;
    &__info {
      display: flex;
      flex-direction: column;
      padding-top: 10vw;
      padding-bottom: 17.07vw;
    }
    &__col {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    &__text {
      font-size: 8vw;
      line-height: 10.67vw;
      color: #3A3E41;
      font-weight: 700;
      width: 82.93vw;
    }
    &__btn {
      margin-top: 9.6vw;
    }
    &__link {
      width: 82.93vw;
      height: 14.13vw;
      background: #08ADA7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF !important;
      text-decoration: none !important;
      font-size: 3.73vw;
      border-radius: 0.53vw;
    }
    &__img {
      width: 82.93vw;
      height: 47.2vw;
    }
    &__slogan {
      display: flex;
      flex-direction: column;
      &--block {
        display: flex;
        align-items: flex-start;
        font-size: 3.73vw;
        text-transform: uppercase;
        color: #3A3E41;
        height: 35.2vw;
        flex-direction: column;
        font-weight: 600;
        justify-content: center;
        border-top: 1px solid #E3E4E5;
      }
    }
  }

  .pct-footer-mobile {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background: #3A3E41;
    display: flex;
    flex-direction: column;
    &__menu {
      padding: 0 8.53vw;
      display: flex;
      height: auto;
    }
    &__logo {
      display: none;
    }
    &__nav {
      padding-top: 19.47vw;
      display: flex;
      //height: 22.5vw;
    }
    &__block {
      &--title {
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 3.27vw;
        line-height: 6.40vw;
        opacity: 70%;
        display: flex;
        align-items: center;
        img {
          width: 2.67vw;
          height: 1.33vw;
          margin-left: 3.2vw;
        }
      }
      &--link {
        padding: 3.2vw 0;
        a {
          color: #A0A9B0;
          font-size: 3.73vw;
          text-decoration: none;
          &:hover {
            color: #08ADA7;
          }
        }

      }
      &--social {
        display: flex;
        margin-top: 3.73vw;
        &-link {
          text-decoration: none;
          cursor: pointer;
          height: 14vw;
          width: 14vw;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #2B2E30;
          border-radius: 5px;
          img {
            height: 7vw;
            width: 7vw;
          }
        }
      }
      .collapsible {
        background-color: #3A3E41;
        border: none;
        text-align: left;
        outline: none;
        font-size: 4.27vw;
        padding: 0;
      }
      .content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
    }
    &__line {
      height: 1px;
      background: #A0A9B0;
      margin: 12.8vw 8.53vw 0 8.53vw;
      opacity: 20%;
    }
    &__info {
      padding: 0 8.53vw;
      height: 31.2vw;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      &--group {
        display: flex;
        font-size: 3.20vw;
        color: #A0A9B0;
        opacity: 70%;
      }
    }
  }
}
