@import url('~normalize.css');
// NOTE: We're using a `.bp-` namespace to indicate these are the boilerplate
// styles.
@import './scss/_settings';
@import './scss/_type';
@import './scss/_layout';
@import './scss/_welcome';
@import './scss/_widgets';
@import './scss/_erp';
@import './scss/_partacart';
